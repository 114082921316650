import { FC } from 'react';

import { DefaultFaq } from './default-slice/DefaultFaq';
import { DefaultFAQsVariation, FAQsVariations } from './types';

export interface FaqSliceProps {
  slice: FAQsVariations;
}

export const FaqSlice: FC<FaqSliceProps> = (props) => {
  const { slice } = props;

  return <DefaultFaq slice={slice as DefaultFAQsVariation} />;
};
