import { FC } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material/';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import { BackgroundColor } from '@lib/slice-machine/types';
import { RichTextField } from '@prismicio/types';

interface Props {
  answer: RichTextField[];
  question: RichTextField[];
  backgroundColor: BackgroundColor | null;
}

export const FaqItem: FC<Props> = ({ answer, question, backgroundColor }) => {
  return (
    <Accordion sx={{ backgroundColor }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <CustomRichText render={question} />
      </AccordionSummary>
      <AccordionDetails>
        <CustomRichText render={answer} />
      </AccordionDetails>
    </Accordion>
  );
};
