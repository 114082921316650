import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid, Stack } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';

import { DefaultFAQsVariation } from '../types';
import { FaqItem } from './FaqItem';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';

interface Props {
  slice: DefaultFAQsVariation;
}

export const DefaultFaq: FC<Props> = ({ slice }) => {
  const columnsPerRow = parseInt(slice.primary.columnsPerRow, 10);
  const {
    titleTextAlign,
    descriptionTextAlign,
    backgroundColor,
    paddingTop,
    paddingBottom,
    width,
  } = slice.primary;
  const dynamicStyles = styles({ titleTextAlign, descriptionTextAlign });

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
    >
      <Stack
        spacing={DEFAULT_SPACING_BETWEEN_ELEMENTS}
        sx={dynamicStyles.getValue('wrapper')}
      >
        {hasRichTextContent(slice.primary.title) && (
          <CustomRichText
            render={slice.primary.title}
            containerSx={dynamicStyles.getValue('title')}
          />
        )}
        {hasRichTextContent(slice.primary.description) && (
          <CustomRichText
            render={slice.primary.description}
            containerSx={dynamicStyles.getValue('description')}
          />
        )}
        <Box>
          <Grid container spacing={1}>
            {slice?.items?.map(
              (item, index) =>
                hasRichTextContent(item.question) && (
                  <Grid item xs={12 / columnsPerRow} key={index}>
                    <FaqItem
                      question={item.question}
                      answer={item.answer}
                      backgroundColor={slice.primary.itemBackgroundColor}
                    />
                  </Grid>
                ),
            )}
          </Grid>
        </Box>
      </Stack>
    </SliceLayout>
  );
};

interface Styles {
  titleTextAlign?: string;
  descriptionTextAlign?: string;
}

const styles = ({
  titleTextAlign = 'center',
  descriptionTextAlign = 'center',
}: Styles) =>
  new SxStyles({
    wrapper: {
      h3: {
        fontSize: 20,
        fontWeight: 'fontWeightMedium',
      },
      h4: {
        fontSize: 18,
      },
      h5: {
        fontSize: 17,
      },
      h6: {
        fontSize: 16,
      },
    },
    title: {
      textAlign: titleTextAlign,
    },
    description: {
      textAlign: descriptionTextAlign,
    },
  });
